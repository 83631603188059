import { Calendar } from '@fullcalendar/core';
import frenchTranslation from '@fullcalendar/core/locales/fr';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import timeGridPlugin from '@fullcalendar/timegrid';
import scrollgridPlugin from '@fullcalendar/scrollgrid';
import interactionPlugin from '@fullcalendar/interaction';

export default class PlanningWeek {

    constructor() {
        this.calendar_week = null;
        this.initial_date = $("#calendar_week").data("initial_date");
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }

    bindEvents() {
        let self = this;

        $(document).ready(self.initCalendar);

        $('#fc_week_next').click(this.prev_week);
        $('#fc_week_prev').click(this.next_week);

        $('a[href="#week_view"]').on("shown.bs.tab", function (){
            self.calendar_week.render();
        })

    }

    onDataChanged(data) {
    }

    onDestroy() {
    }

    initCalendar() {
        let self = application.current_handler.calendar_week;

        self.calendar_week = new Calendar(document.getElementById('calendar_week'), {
            schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
            plugins: [ timeGridPlugin, interactionPlugin, bootstrapPlugin, scrollgridPlugin ],
            themeSystem: 'bootstrap',
            initialView: 'timeGridWeek',
            initialDate: (self.initial_date==null ? new Date() : self.initial_date),
            headerToolbar: {
                start: '',
                center: '',
                end: ''
            },
            slotEventOverlap: false,
            expandRows: true,
            allDaySlot: false,
            slotLabelFormat: {
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: true,
                meridiem: 'short'
            },
            slotMinTime: "06:00:00",
            slotMaxTime: "20:00:00",
            slotDuration: "00:30:00",
            dayMinWidth: 50,
            locales: [ frenchTranslation ],
            locale: I18n.locale,
            //timeZone: 'UTC',
            contentHeight:"auto",
            displayEventTime: false,
            dayHeaderContent: function (arg){
                let day = I18n.l("date.formats.abr_day", arg.date);
                let n = I18n.l("date.formats.number_day", arg.date);
                return { html: '<span class="text-uppercase d-block mb-1 small">'+day[0] + '</span> <span class="fw-700 small">'+ n +'</span>' };
            },
            aspectRatio: (1/(700/$("#calendar_week").width())),
            events: {
                url: '/monitor/planning/events',
                method: 'GET',
                failure: function() {
                    alert('there was an error while fetching events!');
                }
            },
            dateClick: function(arg) { self.onDateClicked(arg) },
            eventClick: self.fcEventClick,
            eventSourceSuccess: function(content, xhr) {
                console.log("eventSourceSuccess -> "+content.events.length);
                return content.events;
            },
            eventContent: function(arg) {

                let main = document.createElement("div");
                main.className = "fc-event-main-frame";
                let container = document.createElement("div");

                container.className = "fc-event-title-container";

                let title = document.createElement("div")
                title.className = "fc-event-title fc-sticky";

                main.appendChild(container);
                container.appendChild(title);

                title.innerHTML = arg.event.title;

                var type = arg.event.extendedProps.event_type;

                if(arg.event.extendedProps.driving_transmission_type!=null && (type=="type_driving_lesson" || type=="type_driving_lesson_duo" || type=="type_driving_lesson_additional_hour" ||  type=="type_pack_hour" || type=="type_free_driving_lesson" || type=="type_external_driving_lesson")) {
                    title.innerHTML+="<br/>";
                    if(arg.event.extendedProps.driving_transmission_type=="manual") {
                        title.innerHTML += "MECA";
                    }
                    else {
                        title.innerHTML += "BEA"
                    }
                }

                let arrayOfDomNodes = [ main ]
                return { domNodes: arrayOfDomNodes }
            },
            businessHours: application.current_handler.business_hours
        });

        self.calendar_week.render();

        self.updateTextDate();
    }

    prev_week(e){
        let self = application.current_handler.calendar_week;
        e.preventDefault();
        self.calendar_week.next();
        self.updateTextDate();
    }

    next_week(e){
        let self = application.current_handler.calendar_week;
        e.preventDefault();
        self.calendar_week.prev();
        self.updateTextDate();
    }

    updateTextDate(){
        let begin = I18n.l("date.formats.day_month", this.calendar_week.getDate());
        let end = I18n.l("date.formats.day_month", this.calendar_week.getDate().addDays(6));

        $("#fc_week_date").text(begin+" > "+end);
    }

    onDateClicked(arg) {
        console.log("date clicked");
        if(moment(arg.date)>moment()) {
            this.loadUnassignedEntryModal(arg.date);
        }
    }

    fcEventClick(info){
        let self = application.current_handler.calendar_week
        //info.event.extendedProps.event_type !== "type_other" &&
        if (
            info.event.extendedProps.event_type !== "type_holiday" &&
            info.event.extendedProps.event_type !== "type_away" &&
            info.event.extendedProps.event_type !== "type_lunch" &&
            info.event.extendedProps.event_type !== "type_not_assigned"
        ){
            self.loadModal(info.event.id);
        }
        else if(info.event.extendedProps.event_type == "type_not_assigned"  && info.event.extendedProps.is_reserved == false) {
            self.loadAdditionalHourModal(info.event.id);
        }
        else {
            console.log("unused slot");
        }
    }

    loadModal(event_id){

        let ajaxData = {
            url: '/monitor/planning/get_modal_content',
            data : {event_id: event_id},
            method: "GET"
        };

        $.ajax(ajaxData).done(function(html) {
            $('#planning_modal_content').empty().append(html);
            $('#planning-modal').modal('show');
        });

    }

    loadAdditionalHourModal(event_id) {
        let ajaxData = {
            url: '/monitor/planning/get_additional_hour_modal_content',
            data : {event_id: event_id},
            method: "GET"
        };

        $.ajax(ajaxData).done(function(html) {
            $('#planning_modal_content').empty().append(html);
            $('#planning-modal').modal('show');
        });
    }

    loadUnassignedEntryModal(date) {
        var self = this;
        let ajaxData = {
            url: '/monitor/planning/get_unassigned_entry_modal_content',
            data : {date: date},
            method: "GET"
        };

        $.ajax(ajaxData).done(function(html) {
            $('#planning_modal_content').empty().append(html);
            $('#planning-modal').modal('show');

            $("#unassigned_entry_form").off("submit").submit(function(e) {
                e.preventDefault();
                $("#unassigned_entry_submit_btn").prop("disabled", true);
                $.ajax({
                  url: $(this).attr('action'),
                  type: 'PUT',
                  data : $(this).serialize(),
                  success: function(json){
                    $('#planning-modal').modal("hide");
                    self.calendar_week.addEvent(json.event, true);
                  }
                });    
            });
        });
    }
}